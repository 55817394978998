.newletter {
  background-color: #1eb2a6;
  padding: 50px 0;
  color: #fff;
}
.newletter .right,
.newletter .left {
  padding-right: 50px;
}
.newletter h1 {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 15px;
}
.newletter .right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.newletter input {
  width: 100%;
  padding: 20px;
  border: none;
  outline: none;
  height: 30px;
}
.newletter i {
  padding: 12px;
  color: #1eb2a6;
  background-color: #fff;
}
footer {
  background-color: #eeeeee;
}
footer .container {
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 4fr 3fr;
  grid-gap: 30px;
  margin-left: 20px;
}
footer .logo span {
  color: #1eb2a6;
  font-size: 14px;
}
footer .logo p {
  color: grey;
  margin: 30px 0 15px 0;
}
footer .logo .icon {
  background-color: #1eb2a6;
  color: white;
  margin-right: 10px;
}
footer h3 {
  margin-bottom: 40px;
  font-weight: 500;
}
footer ul li {
  margin-bottom: 20px;
}
footer .link li::before {
  content: "⟶";
  color: #1eb2a6;
  margin-right: 5px;
}
footer .items {
  margin-bottom: 30px;
}
footer .items img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
  object-fit: cover;
}
footer .items span,
footer .items i {
  font-size: 12px;
  color: #1eb2a6;
  margin-right: 5px;
  text-transform: capitalize;
}
footer h4 {
  font-weight: 400;
  margin-top: 5px;
}
footer .last ul li {
  display: flex;
  margin-bottom: 50px;
}
footer .last i {
  width: 60px;
  font-size: 20px;
  color: #1eb2a6;
}
.legal {
  text-align: center;
  padding: 50px 0;
  color: grey;
  background-color: #e9e9e9;
}
.legal p {
  font-size: 15px;
}
.legal i {
  color: #1eb2a6;
}
.left.row {
  padding-left: 50px;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .container.flexSB {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center; /* Center text for mobile view */
    padding: 0 15px; /* Add some padding to the container */
  }

  .newletter .left {
    padding: 0;
  }

  .left.row {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .newletter h1 {
    font-size: 24px; /* Adjust font size for smaller screens */
    margin-bottom: 10px;
  }

  .newletter span {
    display: block;
    margin: 10px 0;
  }
}
@media screen and (max-width: 768px) {
  footer .container {
    grid-template-columns: 1fr;
  }

  footer .items {
    flex-direction: column;
    align-items: center;
  }

  footer .items img {
    margin-bottom: 10px;
  }

  footer .last ul li {
    flex-direction: column;
    align-items: flex-start;
  }
}
