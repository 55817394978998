.hero {
  background-image: url("../../../../public/images/bg.jpeg");
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  padding-top: 20%;
  padding-right: 50px;
  color: #fff;
  padding-left: 30px;
}
.hero #heading h3 {
  color: #fff;
}
.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}
.text123 {
  color: #fff;
}
.text122 {
  font-size: 20px;
}
.heading_text123 {
  font-size: 35px;
  padding-bottom: 16px;
}
.primary-btn {
  background-image: linear-gradient(to right, #5081e4, #002a7b);
}
@media screen and (max-width: 768px) {
  .margin {
    height: 40vh;
  }
  .hero {
    background-position: right;
    padding-top: 20%;
    height: 80vh;
    margin-top: 70px;
  }
  .hero .row {
    width: 100%;
    margin-top: 70px;
  }
  .text122 {
    font-size: 16px;
  }
  .heading_text123 {
    font-size: 20px;
    padding-bottom: 3px;
  }
}
