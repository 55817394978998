/* Add this CSS to your App.css or the appropriate CSS file */

/* Ensure the container213 class enables horizontal scrolling */
.container213 {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  padding: 20px;
  gap: 20px; /* Space between items */
  scroll-snap-type: x mandatory; /* Optional: For snap scrolling */
}

/* Ensure items class has appropriate styling */
.items {
  min-width: 300px; /* Set a minimum width for each card */

  padding: 40px 10px;
  text-align: center;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  scroll-snap-align: start; /* Optional: For snap scrolling */
}

/* Override .price.container.grid to remove grid layout */
.price.container.grid {
  display: flex; /* Change from grid to flex */
  flex-wrap: nowrap; /* Ensure no wrapping */
  overflow-x: auto; /* Enable horizontal scrolling */
}

/* Additional styling for items */
.price h1 {
  font-size: 50px;
  margin: 20px 0;
  color: #1eb2a6;
}

.price h1 span {
  font-size: 20px;
  margin-right: 5px;
  color: #000;
}

.price p {
  color: grey;
  margin: 40px 0;
}

.items .outline-btn {
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid #007bff;
  color: #007bff;
  background: transparent;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
}

.items .outline-btn:hover {
  background: #007bff;
  color: #fff;
}
.item.shadow {
  margin-left: 20px;
}
