.testimonal .items {
  padding: 30px;
  transition: 0.5s;
}

.testimonal .img {
  position: relative;
}
.testimonal img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.testimonal i {
  position: absolute;
  bottom: 0;
  left: 60px;
  background-color: #1eb2a6;
  color: #fff;
}
.testimonal .name {
  margin: 25px;
}
.testimonal .name h2 {
  font-size: 20px;
  margin-bottom: 5px;
}
.testimonal .name span {
  color: #1eb2a6;
}
.testimonal p {
  margin-top: 20px;
  color: grey;
  transition: 0.5s;
}
.testimonal .items:hover {
  background-color: #1eb2a6;
  color: #fff;
  cursor: pointer;
}
.testimonal .items:hover span,
.testimonal .items:hover p {
  color: #fff;
}
.testimonal .items:hover i {
  background-color: #fff;
  color: #1eb2a6;
}

/* new code  */

/* App.css */

/* Testimonial section styling */
.testimonal {
  padding: 40px 0;
}

.testimonal .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.testimonal .content {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  scroll-snap-type: x mandatory; /* Optional: for snapping effect */
}

.testimonal .items {
  flex: 0 0 auto; /* Prevent items from shrinking */
  width: 300px; /* Set a fixed width for each item */
  height: 400px;
  margin-right: 20px;
  scroll-snap-align: start; /* Optional: for snapping effect */
}

.testimonal .items .box {
  display: flex;
  align-items: center;
}

.testimonal .items .img {
  position: relative;
}

.testimonal .items .img img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.testimonal .items .img .icon {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 20px;
  color: #000;
}

.testimonal .items .name {
  margin-left: 20px;
}

.testimonal .items .name h2 {
  margin: 0;
  font-size: 18px;
}

.testimonal .items .name span {
  display: block;
  font-size: 14px;
  color: #888;
}

.testimonal .items p {
  margin: 10px 0 0;
  font-size: 14px;
  color: #666;
}
.fa-quote-left {
  margin-left: -20px;
  margin-top: 50px;
}
